import dynamic from 'next/dynamic'
import React from 'react'
import { AppProps } from 'next/app'
import Context from 'components/Context'
import { logError } from '@sailogy/shipwright'
import i18nError from 'config/i18n-error.json'
// TEMP Polyfills
import 'intersection-observer'
import 'abortcontroller-polyfill'
// TEMP New styles
import 'styles/fonts/opensans.css'
import 'normalize.css'
import 'styles/theme.css'
import '@sailogy/shipwright/dist/index.css'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

const Chat = dynamic(
  () => import('@sailogy/components/Chat'),
  { ssr: false }
)


class App extends React.Component<AppProps> {
  public componentDidMount() {
    // Run GTM assignments on CSR only
    if (typeof window !== 'undefined') {
      // Force DataLayer init
      if (!window.dataLayer) window.dataLayer = []
    }
  }

  public componentDidCatch(error: any, errorInfo: any) {
    // Log the error
    logError('App Unhandled Error', errorInfo, error)
  }

  public render() {
    const {
      Component, pageProps: { locale, token, i18n, i18nPath, ...pageProps }
    } = this.props

    // WARNING: Static pages doesn't have params
    // Static error pages do not trigger params
    return (
      <Context token={token} i18n={i18n || i18nError} i18nPath={i18nPath || 'error'}>
        <Component {...pageProps} />
        {process.env.CHATLIO_ID && <Chat widgetId={process.env.CHATLIO_ID} />}
      </Context>
    )
  }
}

export default App
