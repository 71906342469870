// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import options from './sentry.config.options'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://e9112cbfb9d64397b8a9188ebcdf7f0f@o10778.ingest.sentry.io/5261793',
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  // https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/#decluttering-sentry
  ...options
})
