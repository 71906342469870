export default {
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  // https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/#decluttering-sentry
  ignoreErrors: [
    'connect ECONNREFUSED',
    'ETIMEDOUT',
    'ECONNRESET',
    'NetworkError',
    'TimeoutError',
    'ReferenceError',
    'SecurityError',
    'Bad Gateway',
    'Gateway Time-out',
    'Unexpected error',
    'Illegal invocation',
    'socket hang up',
    // Abort request
    'AbortRequest',
    'aborted',
    // Not found
    'Cruise not found',
    // Cookiebot
    'Cookiebot',
    // Hapi bad request
    'Invalid accept-language header',
    // Facebook borked
    'fb_xd_fragment'
  ],
  denyUrls: [
    // Next.js chunks
    /_next/i,
    // Chrome/Safari extensions
    /extension\//i,
    // Node modules
    /node_modules/i,
    /slideContainer/i,
    // API requests
    /hapi/i,
    /node-fetch/i,
    // REST API
    /sailogy\.rest/i,
    /old\.sailogy\.com/i,
    // GTM
    /googletagmanager\.com/i,
    // Google Maps
    /googleapis\.com/i,
    /maps/i,
    // Clarity
    /clarity/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Cookiebot
    /Cookiebot/i,
    // Chatlio
    /chatlio/i,
    // Insider
    /useinsider\.com/i
  ]
}
